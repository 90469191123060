var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.shouldShowHomeView ? _c('div', {
    attrs: {
      "id": "home",
      "data-app": "true"
    }
  }, [_vm.currentUser && _vm.currentUser.id ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('TopPages', {
    attrs: {
      "user-id": _vm.currentUser.id
    }
  })], 1)], 1) : _vm._e(), false ? _c('v-layout', {
    staticClass: "mb-3",
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppHeader', [_vm._v("Quick links")])], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({
          staticClass: "d-inline-block"
        }, on), [_c('ReportButton', {
          attrs: {
            "href": _vm.myInventoryLink,
            "title": "View My Inventory"
          }
        })], 1)];
      }
    }], null, false, 2975838195)
  }, [_c('span', [_vm._v("Lists all Inventory matching the selected criteria")])]), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('div', _vm._g({
          staticClass: "d-inline-block"
        }, on), [_c('ReportButton', {
          attrs: {
            "href": "/reporting/paperwork",
            "title": "Open Paperwork"
          }
        })], 1)];
      }
    }], null, false, 1347700228)
  }, [_c('span', [_vm._v("List all Document line items that are open for the selected criteria")])]), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on;
        return [_c('div', _vm._g({
          staticClass: "d-inline-block"
        }, on), [_c('ReportButton', {
          attrs: {
            "href": "/reporting/hardware_activity",
            "title": "Hardware Activity"
          }
        })], 1)];
      }
    }], null, false, 4036896367)
  }, [_c('span', [_vm._v("Lists all Activites related to the selected Item Instance")])]), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on;
        return [_c('div', _vm._g({
          staticClass: "d-inline-block"
        }, on), [_c('ReportButton', {
          attrs: {
            "href": "/reporting",
            "title": "View all Reports"
          }
        })], 1)];
      }
    }], null, false, 848516942)
  }, [_c('span', [_vm._v("Filterable list of every report in COSMIC")])])], 1)], 1) : _vm._e(), _vm.shouldShowInTransitTable || _vm.shouldShowInventoryDueBackTable || _vm.shouldShowUnapprovedItemMastersTable ? _c('AppExpansionPanel', {
    attrs: {
      "color": _vm.EntityType.INVENTORY,
      "isOpen": true
    }
  }, [_c('v-flex', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons.inventories,
      "icon-color": "white"
    }
  }), _c('span', {
    staticClass: "title"
  }, [_vm._v("Inventory dashboard")])], 1), _c('v-fade-transition', [_vm.shouldShowInTransitTable ? _c('InventoryInTransitTable', {
    staticClass: "mb-3",
    attrs: {
      "items": _vm.inTransitTableItems,
      "rows-per-page-items": _vm.rowsPerPageItems
    }
  }) : _vm._e()], 1), _c('v-fade-transition', [_vm.shouldShowInventoryDueBackTable ? _c('InventoryDueBackTable', {
    staticClass: "mb-3",
    attrs: {
      "items": _vm.inventoryDueBackItems,
      "rows-per-page-items": _vm.rowsPerPageItems
    }
  }) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowUnapprovedItemMastersTable ? _c('AppTableActions', {
    attrs: {
      "color": _vm.EntityType.ITEM_MASTER,
      "headers": _vm.computedUnapprovedItemMasterTableColumns,
      "items": _vm.computedUnapprovedItemMasters,
      "rows-per-page-items": _vm.rowsPerPageItems,
      "disable-initial-sort": "",
      "hide-icon": "",
      "sticky": "",
      "title": "Item Masters in need of Initialization"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('ButtonMiniAction', {
          attrs: {
            "icon-color": "var(--v-".concat(_vm.EntityType.CHANGE_SET, "-base)"),
            "icon": _vm.$icons[_vm.EntityType.CHANGE_SET],
            "tooltip": "Start Item Master initialization process",
            "tooltip-position": "left"
          },
          on: {
            "click": function click($event) {
              return _vm.onClickOfStartItemMasterInitialization(item);
            }
          }
        }), _c('ButtonMiniActionInfo', {
          on: {
            "click": function click($event) {
              return _vm.onClickOfViewItemMasterDetails(item);
            }
          }
        })];
      }
    }], null, false, 4165134644)
  }) : _vm._e()], 1), _vm.shouldShowAhdModal ? _c('ModalAppHardwareDisplay', {
    attrs: {
      "node-id": _vm.selectedRowItemMasterNodeId
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.selectedUnapprovedItemMaster = null;
      }
    }
  }) : _vm._e(), _vm.shouldShowItemMasterInitModal ? _c('ModalItemMasterInitialization', {
    attrs: {
      "item-master-node-id": _vm.selectedRowItemMasterNodeId
    },
    on: {
      "modal-close": _vm.onCloseOfItemMasterInitModal,
      "entityCreated": _vm.onInitializationChangeSetCreated
    }
  }) : _vm._e(), _c('v-fade-transition', [_vm.shouldShowNoExpectedShipmentAlert ? _c('Alert', {
    attrs: {
      "type": "info",
      "text": "There are currently no expected shipments."
    }
  }) : _vm._e()], 1), _c('v-fade-transition', [!_vm.finishedInitialFetchForInventoryDashboard ? _c('AppLoader', {
    attrs: {
      "type": "linear"
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm.shouldShowOpenItemsDashboardPanel ? _c('AppExpansionPanel', {
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "is-open": false
    }
  }, [_c('v-flex', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("Open items dashboard")])]), _c('OnOrbitFailuresTable', {
    attrs: {
      "loading": !_vm.finishedInitialFetchForOpenFailures,
      "items": _vm.openFailuresTableItems,
      "expandable-rows": "",
      "hide-closed-date": "",
      "title": "Open Failures"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('MiniFailure', {
          staticClass: "mb-3",
          attrs: {
            "node-id": props.item.id
          }
        }), _c('ReadOnlyChangeSet', {
          attrs: {
            "id": props.item.id,
            "hideAhd": "",
            "hideMini": ""
          }
        })];
      }
    }], null, false, 4234789379)
  })], 1) : _vm._e(), _c('LatestThings')], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };