var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.title ? _c('AppHeader', {
    attrs: {
      "size": "mini"
    }
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.transformedSelectedEntity ? _c('div', {
    staticClass: "entity-mini inventories",
    class: {
      'no-entity-icon': !_vm.showEntityTypeIcon
    }
  }, [_vm.showEntityTypeIcon ? _c('div', {
    staticClass: "mini_icon_sequence_container"
  }, [!_vm.shouldShowSequence ? _c('c-icon-fa', {
    staticClass: "entity-mini-icon",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.INVENTORY],
      "icon-size": "32px",
      "icon-color": "white"
    }
  }) : _c('div', {
    staticClass: "mini_chips"
  }, [_c('v-chip', {
    staticClass: "mini_chip seq_chip",
    attrs: {
      "disabled": ""
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_c('strong', [_vm._v(_vm._s(_vm.sequence))])])])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "entity-mini-name text-truncate white--text"
  }, [_vm.transformedSelectedEntity ? _c('div', {
    staticClass: "mini-name text-truncate display-1"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.drawingNumber) + " ")])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.itemDrawingDescription ? _c('small', {
    staticClass: "caption pl-1",
    attrs: {
      "title": "Description"
    }
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._description))]) : _vm._e(), !_vm.isExternal && _vm.transformedSelectedEntity && _vm.transformedSelectedEntity._location ? _c('small', {
    staticClass: "caption pl-1"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._location._organizationDisplay))]) : _vm._e()]), _vm.isExternal ? _c('div', {
    staticClass: "white--text text--lighten3 d-flex justify-center"
  }, [_c('span', {
    staticClass: "icon_wrap"
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({}, on), [_c('IconNotCosmic')], 1)];
      }
    }], null, false, 1347555132)
  }, [_c('span', [_vm._v("Non-" + _vm._s(_vm.currentSystemName) + " Inventory")])])], 1)]) : _vm._e(), _vm.isExternal ? _c('div') : _vm._e(), !_vm.isExternal && _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "white--text d-flex justify-center layout column text-xs-center"
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("AsBuilt")]), _c('div', {
    staticClass: "font-weight-bold monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._asBuiltNumber))])]) : _vm._e(), !_vm.isExternal && _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "white--text d-flex justify-center layout column text-xs-center"
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("Side")]), _c('div', {
    staticClass: "font-weight-bold monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._side))])]) : _vm._e(), !_vm.isExternal && _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "white--text d-flex justify-center layout column text-xs-center"
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("Size")]), _c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._size))])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.serialNumber ? _c('div', {
    staticClass: "white--text d-flex justify-center layout column text-xs-center"
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("Serial")]), _c('div', {
    staticClass: "font-weight-bold monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._serialNumber))])]) : _vm._e(), _vm.transformedSelectedEntity && _vm.transformedSelectedEntity.lotNumber ? _c('div', {
    staticClass: "white--text d-flex justify-center layout column text-xs-center"
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("Lot")]), _c('div', {
    staticClass: "font-weight-bold monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._lotNumber))])]) : _vm._e(), _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "white--text d-flex justify-center layout column text-xs-center"
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("Class")]), _c('div', {
    staticClass: "font-weight-bold monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._subType))])]) : _vm._e(), _c('div', {
    staticClass: "mini_actions_v2 justify-end"
  }, [!_vm.entity ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('div', _vm._g({}, on), [_c('ButtonMiniAction', {
          attrs: {
            "icon-color": "#fff",
            "icon": _vm.$icons['eye']
          },
          on: {
            "click": _vm.onClickOfShowDetail
          }
        })], 1)];
      }
    }], null, false, 3741113595)
  }, [_c('span', [_vm._v("Show/Hide detail")])]) : _vm._e(), _vm._t("actions", null, {
    "localEntity": _vm.transformedSelectedEntity
  }), _vm.inlineClear && !_vm.readOnly ? _c('ButtonMiniAction', {
    attrs: {
      "icon-color": "#fff",
      "icon": _vm.$icons.close,
      "tooltip": "Clear selection"
    },
    on: {
      "click": _vm.onClickOfInlineClear
    }
  }) : _vm._e()], 2)]) : _vm._e()]), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.transformedSelectedEntity ? _c('AppLoader', {
    key: "entity-loader",
    attrs: {
      "color": _vm.EntityType.ITEM_INSTANCE,
      "type": "linear",
      "center": ""
    }
  }) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowDetail && _vm.transformedSelectedEntity ? _c('AppFormWrapper', {
    attrs: {
      "bottom": "1",
      "color-modifier": "",
      "color": _vm.EntityType.INVENTORY,
      "left": "1",
      "right": "1",
      "size": "1",
      "top": "0"
    }
  }, [_c('div', {
    staticClass: "inventory_mini_details"
  }, [_c('AppHardwareDisplay', {
    attrs: {
      "id": _vm.transformedSelectedEntity.itemInstanceId,
      "inventory-id": _vm.transformedSelectedEntity.id,
      "hide-border-bottom": ""
    }
  })], 1)]) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };