var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { CosmicItemMasterSlimFragment } from '~/nasa_ui/DAL/itemMaster/slim';
import { FederalSupplyClassDisplay } from '~/nasa_ui/types/enums/federal-supply-class';
import { ItemMasterPropertyClassDisplay } from '~/nasa_ui/types/enums/hardware';
let ChangeSetDisplayItemMasterFederalSupplyOrPropertyClassChange = class ChangeSetDisplayItemMasterFederalSupplyOrPropertyClassChange extends Vue {
    id;
    selectedEntity = null;
    isFetching = false;
    get itemMaster() {
        return this.selectedEntity && this.selectedEntity.itemMaster ? this.selectedEntity.itemMaster : {};
    }
    get currentValuesForDisplay() {
        // format the value using the appropriate display
        return Object.keys(this.payload).reduce((out, key) => {
            const value = this.itemMaster[key];
            if (key === 'federalSupplyClass') {
                out[key] = FederalSupplyClassDisplay.get(value);
            }
            if (key === 'propertyClass') {
                out[key] = ItemMasterPropertyClassDisplay.get(value);
            }
            return out;
        }, {});
    }
    get changesForDisplay() {
        // format the value of each change using the appropriate display
        return Object.keys(this.payload).reduce((out, key) => {
            const value = this.payload[key];
            if (key === 'federalSupplyClass') {
                out[key] = FederalSupplyClassDisplay.get(value);
            }
            if (key === 'propertyClass') {
                out[key] = ItemMasterPropertyClassDisplay.get(value);
            }
            return out;
        }, {});
    }
    get payload() {
        return this.selectedEntity ? this.selectedEntity.payload : {};
    }
    changesInclude(property) {
        return Object.keys(this.changesForDisplay).includes(property);
    }
    async fetchEntity(id) {
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicItemMasterSlimFragment}

          query SelectChangeSetForItemMasterFederalSupplyOrPropertyClassChange($id: Int!) {
            changeSetById(id: $id) {
              id
              nodeId
              subType
              payload
              itemMaster {
                ...CosmicItemMasterSlim
              }
            }
          }
        `,
                variables: {
                    id
                },
                fetchPolicy: 'network-only'
            });
            this.selectedEntity = resp.data.changeSetById;
            this.isFetching = false;
            return resp.data.changeSetById;
        }
        catch (error) {
            this.$errorUtility({ err: error });
            this.isFetching = false;
        }
    }
    syncId(id) {
        if (id) {
            this.fetchEntity(id);
        }
    }
};
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Object)
], ChangeSetDisplayItemMasterFederalSupplyOrPropertyClassChange.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], ChangeSetDisplayItemMasterFederalSupplyOrPropertyClassChange.prototype, "syncId", null);
ChangeSetDisplayItemMasterFederalSupplyOrPropertyClassChange = __decorate([
    Component
], ChangeSetDisplayItemMasterFederalSupplyOrPropertyClassChange);
export default ChangeSetDisplayItemMasterFederalSupplyOrPropertyClassChange;
