import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { ChangeSetStatus, ChangeSetType, CommentType } from '~/db_types';
import BaseModalWithUser from '~/nasa_ui/base/BaseModalWithUser';
import { CosmicChangeSetStatusFragment } from '~/nasa_ui/DAL/changeSet';
import { CosmicItemMasterSlimFragment } from '~/nasa_ui/DAL/itemMaster/slim';
import { AlertType, EntityType } from '~/nasa_ui/types';
import { UserPermission } from '~/nasa_ui/types/enums/user';
import { UserGroupTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformEntitiesToTableFormat } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import { createChangeSetWithComment } from '~/utils/index';
let ModalItemMasterInitialization = class ModalItemMasterInitialization extends BaseModalWithUser {
    approvalTemplates = null;
    formData = {
        authorityCode: null,
        authorityNumber: null,
        comment: null
    };
    itemMaster = null;
    approvalRoutingTableHeaders = [...UserGroupTableHeaders].map((header) => {
        header.sortable = false;
        return header;
    });
    itemMasterNodeId;
    get approvalRoutingTableItems() {
        if (!this.approvalTemplates) {
            return [];
        }
        return transformEntitiesToTableFormat(EntityType.USER_GROUP, this.approvalTemplates
            .filter((template) => template.itemMasterType === this.itemMaster?.subType)
            .map((template) => template?.userGroup));
    }
    get isAutoApprove() {
        return this.approvalRoutingTableItems.length === 0;
    }
    get userHasPermission() {
        return this.currentUserHasPermission(UserPermission.ITEM_MASTER_ADMIN);
    }
    get isFormValid() {
        if (this.openInitializationChangeSet) {
            return false;
        }
        return Boolean(this.formData.authorityCode && this.formData.authorityNumber);
    }
    get openInitializationChangeSet() {
        return this.itemMaster?.changeSets.nodes.find((cs) => cs?.status?.status === ChangeSetStatus.OPEN) || null;
    }
    get openInitializationChangeSetHref() {
        return this.openInitializationChangeSet
            ? `/${EntityType.CHANGE_SET}/manage/${this.openInitializationChangeSet.id}`
            : null;
    }
    get shouldDisableSubmit() {
        return !this.isFormValid;
    }
    get initializationChangeSetCommentUtilOpts() {
        if (!this.isFormValid) {
            return null;
        }
        const payload = {
            initializationAuthorityNumber: this.formData.authorityNumber,
            initializationCode: this.formData.authorityCode
        };
        return {
            changeSet: {
                asBuiltNumber: this.itemMaster?.asBuiltNumber,
                drawingNumber: this.itemMaster?.drawingNumber,
                openedDate: this.$dateDisplay(new Date()),
                payload,
                side: this.itemMaster?.side,
                subType: ChangeSetType.ITEM_MASTER_INITIALIZATION
            },
            comment: {
                // if no comment text, the util will just create the change set
                comment: this.formData.comment || '',
                subType: CommentType.INITIALIZATION_COMMENT
            }
        };
    }
    async fetchEntity(nodeId) {
        if (!nodeId) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicItemMasterSlimFragment}
          ${CosmicChangeSetStatusFragment}

          fragment ItemMasterInitializationChangeSet on ChangeSet {
            id
            nodeId
            payload
            status {
              ...CosmicChangeSetStatus
            }
          }

          fragment ItemMasterInitialization on ItemMaster {
            ...CosmicItemMasterSlim

            changeSets(filter: { subType: { equalTo: ITEM_MASTER_INITIALIZATION } }) {
              nodes {
                ...ItemMasterInitializationChangeSet
              }
            }
          }

          fragment ItemMasterInitializationApprovalTemplate on ChangeSetTemplate {
            nodeId
            itemMasterType
            userGroup {
              nodeId
              code
              name
              usersToUserGroups {
                totalCount
              }
            }
          }

          query FetchItemMasterInitializationData($nodeId: ID!) {
            itemMaster(nodeId: $nodeId) {
              ...ItemMasterInitialization
            }

            approvalTemplates: changeSetTemplates(
              filter: { subType: { equalTo: ITEM_MASTER_INITIALIZATION }, isActive: { equalTo: true } }
            ) {
              nodes {
                ...ItemMasterInitializationApprovalTemplate
              }
            }
          }
        `,
                variables: {
                    nodeId: nodeId
                },
                fetchPolicy: 'network-only'
            });
            this.isFetching = false;
            if (resp.data.itemMaster) {
                this.itemMaster = resp.data.itemMaster;
            }
            if (resp.data.approvalTemplates) {
                this.approvalTemplates = resp.data.approvalTemplates
                    .nodes;
            }
        }
        catch (err) {
            this.isFetching = false;
            this.$errorUtility({
                err
            });
        }
    }
    async createEntity(payload) {
        this.isSaving = true;
        // not putting this in a try/catch because the util is already handling errors
        await createChangeSetWithComment(payload);
        if (this.$parent) {
            this.$parent.$emit('refetchEntity');
        }
        this.isSaving = false;
    }
    editEntity(payload, nodeId) {
        throw new Error('Method not implemented.');
    }
    deleteEntity(nodeId) {
        throw new Error('Method not implemented.');
    }
    closeDialog() {
        this.emitModalClose();
    }
    onSubmitOfInitialization() {
        if (this.initializationChangeSetCommentUtilOpts) {
            this.createEntity(this.initializationChangeSetCommentUtilOpts);
            // This forces through some triggers so we have to wait a little on auto approves
            this.$notification.add({
                type: this.isAutoApprove ? AlertType.INFO : AlertType.SUCCESS,
                text: this.isAutoApprove
                    ? 'Auto-approvals take a smidge longer for data to settle. Stand by.'
                    : 'A Change Set has been opened for the initialization of this Item.'
            });
            this.emitEntityCreated('Item Master submitted for initialization.');
            this.closeDialog();
        }
    }
    onItemMasterNodeIdChange() {
        if (this.itemMasterNodeId) {
            this.fetchEntity(this.itemMasterNodeId);
        }
    }
};
__decorate([
    Prop({ type: String }),
    __metadata("design:type", Object)
], ModalItemMasterInitialization.prototype, "itemMasterNodeId", void 0);
__decorate([
    Watch('itemMasterNodeId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalItemMasterInitialization.prototype, "onItemMasterNodeIdChange", null);
ModalItemMasterInitialization = __decorate([
    Component
], ModalItemMasterInitialization);
export default ModalItemMasterInitialization;
