var _a;
import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { ItemInstanceInventoryTypeDisplay } from '~/nasa_ui/types/enums/hardware';
let ChangeSetPayloadDisplayItemInstanceInventoryClassChange = class ChangeSetPayloadDisplayItemInstanceInventoryClassChange extends Vue {
    isFetching = false;
    changeSet;
    get subType() {
        return this.changeSet?.payload.subType
            ? ItemInstanceInventoryTypeDisplay.get(this.changeSet?.payload.subType)
            : DEFAULT_DASH;
    }
    get subTypeOld() {
        return this.changeSet?.changeLogs?.nodes[0]
            ? ItemInstanceInventoryTypeDisplay.get(this.changeSet?.changeLogs.nodes[0].changeDetails.changedFrom.subType)
            : DEFAULT_DASH;
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ChangeSetPayloadDisplayItemInstanceInventoryClassChange.prototype, "changeSet", void 0);
ChangeSetPayloadDisplayItemInstanceInventoryClassChange = __decorate([
    Component
], ChangeSetPayloadDisplayItemInstanceInventoryClassChange);
export default ChangeSetPayloadDisplayItemInstanceInventoryClassChange;
